<template>
  <v-container
    class="tabBackground"
  >
    <v-row>
      <v-col cols="1" />
      <v-col cols="7">
        <v-text-field
          v-model="searchText"
          append-icon="search"
          :label="$t('userSettingsTab.search')"
          color="grey darken-1"
          single-line
          hide-details
        />
      </v-col>
      <v-col cols="3"
             class="align-button-end"
      >
        <v-btn
          color="secondary"
          class="mx-2"
          @click.stop="showUserPopup(true)"
        >
          {{ $t('userSettingsTab.add') }}
        </v-btn>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-data-table
          :items="users"
          :headers="headers"
          :search="searchText"
          :loading="loading"
          item-key="id"
          @add-item="addUser"
          @edit-item="editUser"
          @delete-item="confirmSoftDelete"
        >
          <template #[`item.username`]="{ item }">
            <td>
              <span>{{ item.username }}</span>
            </td>
          </template>

          <template #[`item.displayname`]="{ item }">
            <td>
              <span>{{ item.displayname }}</span>
            </td>
          </template>

          <template
            #[`item.active`]="{item}"
          >
            <v-switch
              v-model="item.active"
              :disabled="!canActivateUser(item) || item.id === loggedUser().id"
              color="secondary"
              @click="showDeactivateUserPopup(item)"
            />
          </template>
          <template
            #[`item.access_level`]="{item}"
          >
            <td>
              <span>
                {{ getPermissionName(item.access_level) }}
              </span>
            </td>
          </template>

          <template #[`item.email`]="{ item }">
            <td>
              <span>{{ item.email }}</span>
            </td>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu bottom
                    right
            >
              <template v-if="canActivateUser(item)"
                        #activator="{ on, attrs }"
              >
                <v-icon
                  class="mx-2 hover-actions"
                  v-bind="attrs"
                  v-on="on"
                >
                  more_vert
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="editUser(item)">
                  <v-list-item-title>{{ $t('userSettingsTab.edit') }}</v-list-item-title>
                </v-list-item>
                <!-- Only show delete option if the delete API exists -->
                <v-list-item v-if="deleteApiExists"
                             @click="confirmSoftDelete(item)"
                >
                  <v-list-item-title>{{ $t('userSettingsTab.delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="1" />
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <ValidationObserver ref="observer"
                          v-slot="{ invalid, handleSubmit}"
      >
        <v-card>
          <v-card-title class="text-h6 white--text primary">
            <span class="text-h5">
              {{ tmpUser.id ?
                $t('userSettingsTab.editUser.title') :
                $t('userSettingsTab.addUser.title')
              }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="isCognitoEnabled() ? 'displayname' : 'username'"
                  rules="required|max:20|alpha_num"
                >
                  <v-text-field
                    v-if="isCognitoEnabled() && tmpUser.id"
                    v-model="tmpUser.displayname"
                    :error-messages="errors"
                    :success="valid"
                    :label="$t('userSettingsTab.username')"
                    color="secondary"
                  />
                  <v-text-field
                    v-else
                    v-model="tmpUser.username"
                    :error-messages="errors"
                    :success="valid"
                    :label="$t('userSettingsTab.username')"
                    color="secondary"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="tmpUser.email"
                    :error-messages="errors"
                    :success="valid"
                    :label="$t('userSettingsTab.email')"
                    color="secondary"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row v-if="!isCognitoEnabled()">
              <v-col
                v-if="canSetPassword"
              >
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="password"
                  rules="min:6|max:100|confirmed:password_confirmation"
                >
                  <v-text-field
                    v-model="tmpUser.password"
                    :error-messages="errors"
                    :success="valid"
                    :label="$t('userSettingsTab.password')"
                    type="password"
                    color="secondary"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row v-if="!isCognitoEnabled()">
              <v-col
                v-if="canSetPassword"
              >
                <ValidationProvider v-slot="{ errors, valid }"
                                    name="password_confirmation"
                                    vid="password_confirmation"
                >
                  <v-text-field
                    v-model="tmpUser.password_confirmation"
                    :error-messages="errors"
                    :success="valid"
                    :label="$t('userSettingsTab.confirmPassword')"
                    type="password"
                    color="secondary"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row v-if="isCognitoEnabled() && tmpUser.id">
              <v-col>
                <v-btn
                  color="secondary"
                  @click="resetPassword(tmpUser)"
                >
                  {{ $t('userSettingsTab.editUser.reset') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="tmpUser.id !== loggedUser().id">
              <v-col>
                <ValidationProvider v-slot="{ errors }"
                                    rules="required"
                                    name="access_level"
                >
                  <p>{{ $t('userSettingsTab.permissionLevel') }}*</p>
                  <v-btn-toggle
                    v-model="tmpUser.access_level"
                    :error-messages="errors"
                    name="access_level"
                    required
                  >
                    <v-btn
                      v-for="permission in visibleAccessLevels()"
                      :key="permission.accessLevel"
                      :value="permission.accessLevel"
                      color="secondary white--text v-btn"
                      class="text-caption"
                      text
                    >
                      {{ permission.name }}
                    </v-btn>
                  </v-btn-toggle>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary white--text"
              :disabled="invalid"
              class="mx-2"
              @click="handleSubmit(addUser)"
            >
              {{ tmpUser.id ?
                $t('userSettingsTab.editUser.ok') :
                $t('userSettingsTab.addUser.ok')
              }}
            </v-btn>
            <v-btn
              color="gray darken-3"
              class="mx-2"
              @click="dialog = false"
            >
              {{ $t('userSettingsTab.addUser.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
    <v-dialog
      v-model="dialogDeactivateUser"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title
          class="text-h6 white--text primary"
        >
          {{ (currentActivateUser && !currentActivateUser.active) ?
            $t('userSettingsTab.deactivateUser.title') :
            $t('userSettingsTab.activateUser.title')
          }}
        </v-card-title>

        <v-card-text>
          {{ (currentActivateUser && !currentActivateUser.active) ?
            $t('userSettingsTab.deactivateUser.message') :
            $t('userSettingsTab.activateUser.message')
          }}
          <br>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary white--text v-btn"
            @click="activateUser()"
          >
            {{ (currentActivateUser && !currentActivateUser.active) ?
              $t('userSettingsTab.deactivateUser.submit') :
              $t('userSettingsTab.activateUser.submit')
            }}
          </v-btn>
          <v-btn
            color="gray darken-3"
            @click="cancelActivation()"
          >
            {{ $t('userSettingsTab.activateUser.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="errorSnackBar.show"
                color="error"
    >
      <div class="font-weight-bold">
        {{ errorSnackBar.message }}
      </div>
    </v-snackbar>
    <v-snackbar v-model="successSnackbar.show"
                color="secondary"
    >
      <div class="font-weight-bold">
        {{ successSnackbar.message }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import userService from '../../js/services/userService';
import applicationService from '../../js/services/applicationService';

export default {
  name:       'UsersTab',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      errorSnackBar: {
        show:    false,
        message: '',
      },
      successSnackbar: {
        show:    false,
        message: '',
      },
      loading:             'secondary',
      passwordPlaceholder: '*********************************',
      searchText:          '',
      selectedUserId:      [],
      user:                {},
      tmpUser:             {},
      headers:             [
        {
          text:  this.$t('userSettingsTab.table.headers.username'),
          align: 'start',
          value: this.isCognitoEnabled() ? 'displayname' : 'username',
        },
        {
          text:  this.$t('userSettingsTab.table.headers.email'),
          align: 'start',
          value: 'email',
        },
        {
          text:  this.$t('userSettingsTab.table.headers.accessLevel'),
          align: 'start',
          value: 'access_level',
        },
        {
          text:  this.$t('userSettingsTab.table.headers.active'),
          align: 'start',
          value: 'active',
        },
        {
          text:     this.$t('userSettingsTab.table.headers.actions'),
          align:    'start',
          value:    'actions',
          sortable: false,
        },
      ],
      users:                [],
      dialog:               false,
      dialogDeactivateUser: false,
      disabledEditDelete:   true,
      currentActivateUser:  null,
      deleteApiExists:      false,
    };
  },

  async created() {
    this.updateUsers();
  },

  methods: {
    /**
     * Returns logged user.
     *
     * @return {null|Object}
     */
    loggedUser() {
      return userService.getAuthUser();
    },
    /**
     * Whether user can edit the password field.
     */
    canSetPassword() {
      let canSetPassword = false;

      // Manual password creation is only available in the Local mode.
      if (typeof this.user.id === "undefined") {
        canSetPassword = true;
      } else {
        // Only Technician and greater roles can update password in the edit mode.
        // Ovio Internal password cannot be updated.
        canSetPassword = userService.isTechnician()
          && this.user.access_level !== userService.permissions.oVio.accessLevel;
      }

      return canSetPassword;
    },
    isCognitoEnabled() {
      return applicationService.isCognitoEnabled();
    },
    editUser(item) {
      this.tmpUser = {...item};
      this.showUserPopup(false);
      this.selectUser(item, item.id);
    },
    visibleAccessLevels() {
      const orgAdminExists = this.users.some(
        user => user.access_level === userService.permissions.orgAdministrator.accessLevel
      );

      let visibleLevels = userService.getAvailablePermissions().filter(
        permission =>
        permission.accessLevel !== userService.permissions.oVio.accessLevel
      );

      if (orgAdminExists) {
        visibleLevels = visibleLevels.filter(
          permission =>
          permission.accessLevel !== userService.permissions.orgAdministrator.accessLevel
        );
      }

      return visibleLevels;
    },
    /**
     * Whether user can activate / deactivate other user.
     */
    canActivateUser(item, checkEdit = true) {
      const isRole = (role, user) => {
        return user.access_level === userService.permissions[role].accessLevel;
      }

      const currentUser = this.loggedUser();

      const updatedUserAccessLevel = item.access_level;
      const modifiedByAccessLevel = currentUser.access_level;

      const isUpdatedNormal = isRole('user1', item) || isRole('user2', item);

      let canUpdate = true;
      const selfModify = item.id === currentUser.id;
      const sameRole = checkEdit
        ? updatedUserAccessLevel === modifiedByAccessLevel
        : false;

      // User cannot update another user with the same role.
      if (sameRole && !selfModify) {
        canUpdate = false;
      }

      // User cannot update another user with greater role or if the new role is greater.
      if (updatedUserAccessLevel > modifiedByAccessLevel) {
        canUpdate = false;
      }

      // Prevent OrgAdmins from updating ovio permissions
      if (isRole('orgAdministrator', currentUser) && (!isUpdatedNormal && !isRole('administrator', item)) && !selfModify) {
        canUpdate = false;
      }

      // Only Admin and greater roles can update the password.
      if (modifiedByAccessLevel < userService.permissions.administrator.accessLevel && !selfModify) {
        canUpdate = false;
      }

      return canUpdate;
    },
    confirmSoftDelete() {
      // console.log('confirmSoftDelete', item);
    },
    softDeleteUser() {
    // Placeholder for the API call to mark the user as deleted
    },
    /**
     * Load users.
     */
    async updateUsers() {
      const currentUser = this.loggedUser();
      const rootAccessLevel = userService.permissions.oVio.accessLevel;

      this.loading = 'secondary';
      this.users = await userService.getUserList();

      this.users = this.users.filter(user => this.canActivateUser(user, false));
      
      // Sort users alphabetically by display name or username
      this.users.sort((a, b) => {
        return this.isCognitoEnabled()
          ? (a.displayname > b.displayname ? 1 : -1)
          : (a.username > b.username ? 1 : -1);
      });

      this.users.forEach(u => {
        u.isSelectable = currentUser.access_level >= u.access_level;

        // Display name is optional, so if it's not set, use the username as the default
        if (!u.displayname) {
          u.displayname = u.username;
        }

        if (u.access_level === rootAccessLevel) {
          u.isSelectable = currentUser.id === u.id;
        }
      });
      
      this.loading = false;
    },
    /**
     * Returns permission name.
     *
     * @param {integer} accessLevel - Permission identificator.
     *
     * @return {string}
     */
    getPermissionName(accessLevel) {
      return userService.getPermissionName(accessLevel);
    },

    /**
     * Select user for edit or delete.
     *
     *
     * @param {Array} selectedUser - Selected user.
     * @param {integer} selectedUserId - Selected user identificator.
     */
    selectUser(selectedUser, selectedUserId) {
      // console.log('selectUser');
      this.disabledEditDelete = false;
      this.user = selectedUser;
      this.selectedUserId = [];
      this.selectedUserId.push(selectedUserId);
    },

    /**
     * Show add/edit user popup.
     *
     * @param {boolean} isNew - Is new user.
     */
    showUserPopup(isNew) {
      if (isNew) {
        this.tmpUser = {};
        this.$nextTick(() => {
          if (this.$refs.observer) {
            this.$refs.observer.reset();
          }
        });
      } else if (this.selectedUserId.length > 0) {
        this.users.forEach(user => {
          if (user.id === this.selectedUserId[0].id) {
            this.tmpUser = user;
            this.tmpUser.password = this.passwordPlaceholder;
            this.tmpUser.password_confirmation = this.passwordPlaceholder;
          }
        });
        this.$nextTick(() => { this.$refs.observer.validate(); });
      }

      this.dialog = true;
    },
    /**
     * Add or update user.
     */
    async addUser() {
      this.user = {...this.tmpUser}
      this.user.active = true;

      if (!this.user.id) {
        try {
          await userService.createUser({ ...this.user });
          await this.updateUsers();
          setTimeout(() => {
            this.dialog = false;
          }, 1000);
        } catch (error) {
          let errorMessage = this.$t('userSettingsTab.addUser.error');

          if (error.response && error.response.data && error.response.data.includes('User account already exists')) {
            errorMessage = this.$t('userSettingsTab.addUser.userAlreadyExists');
          }

          this.errorSnackBar = {
            message: errorMessage,
            show:    true,
          };
        }
      } else {
        try {
          delete this.user.pswd_reset_guid;
          delete this.user.thumbnail;
          delete this.user.isSelectable;

          if (this.user.password === this.passwordPlaceholder) {
            delete this.user.password;
          }
          
          await userService.updateUser({ 
            ...this.user,
            displayname: this.isCognitoEnabled()
              ? this.user.displayname
              : this.user.username
          });

          await this.updateUsers();

          this.successSnackbar = {
            message: this.$t('userSettingsTab.addUser.success'),
            show:    true,
          };

          setTimeout(() => {
            this.dialog = false;
          }, 250);
        } catch (error) {
          this.errorSnackBar = {
            message: (error && error.data) ? error.data.title : this.$t('userSettingsTab.addUser.error'),
            show:    true,
          };
        }
      }
    },

    async resetPassword(userData) {
      try {
        const data = {
          email:    userData.email,
          username: userData.username,
        };

        // Make the API call using userService
        await userService.adminResetUserPassword(data);

        this.successSnackbar = {
          message: this.$t('userSettingsTab.editUser.passwordResetSuccess'),
          show:    true,
        };
      } catch (error) {
        this.errorSnackBar = {
          message: this.$t('userSettingsTab.editUser.passwordResetError'),
          show:    true,
        };
      }
    },
    /**
     * Show activate/deactivate user popup.
     */
    showDeactivateUserPopup(user) {
      this.currentActivateUser = user;
      this.dialogDeactivateUser = true;
    },

    /**
     * Activate or deactivate user.
     */
    async activateUser() {
      try {
        delete this.currentActivateUser.password;
        delete this.currentActivateUser.password_confirmation;
        delete this.currentActivateUser.pswd_reset_guid;
        delete this.currentActivateUser.thumbnail;
        delete this.currentActivateUser.isSelectable;

        await userService.updateUser(this.currentActivateUser);
        this.dialogDeactivateUser = false;
        this.$nextTick(() => this.updateUsers());
        this.currentActivateUser = null;

        this.successSnackbar = {
          message: this.$t('userSettingsTab.addUser.success'),
          show:    true,
        };
      } catch (error) {
        this.errorSnackBar = {
          message: (error && error.data) ? error.data : this.$t('userSettingsTab.activateUser.error'),
          show:    true,
        };
      }
    },

    cancelActivation() {
      this.dialogDeactivateUser = false;
      this.currentActivateUser = null;
      this.$nextTick(() => this.updateUsers());
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';
  @import '../../css/components/_settingsTab';

  .title {
    background-color: $blue-title;
  }

 </style>
